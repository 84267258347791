import React from "react";

const Toolbar = () => {
    return (
        <header style={{ margin: 25 }}>
            <h1>natan.dev/</h1>
        </header>
    )
};

export default Toolbar;